import React from "react"
import { Card } from "react-bootstrap"
import { Link } from "gatsby"
import { withTrans } from "../../../../i18n/withTrans"

const Index = ({ name, image, region, id, t }) => (
  <>
    <Card style={{ margin: 10, height: "100%" }}>
      <Card.Body>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div class="member-img-circle">
            <img
              alt=""
              src={`${
                // image ? image : "../../../../images/Profil_default-image.png"
                image ? image : "https://svc.gakeslab.apps360.id/v1/file/logo/default-member.png"
              }`}
            />
          </div>
          <div style={{ marginTop: 40, textAlign: "center" }}>
            <span style={{ fontSize: 18, fontWeight: 700, color: "#183625" }}>
              {name}
            </span>
          </div>
          <div style={{ marginBottom: 15, textAlign: "center" }}>
            <span style={{ fontSize: 12, fontWeight: 400, color: "#183625" }}>
              {region}
            </span>
          </div>
        </div>
      </Card.Body>
      <Card.Body
        style={{
          color: "#00A650",
          textAlign: "center",
          borderTop: "1px solid rgba(0,0,0,.125)",
          padding: 6,
          fontSize: 12,
          height: 20,
          position: "absolute",
          bottom: "10px",
          width: "100%",
        }}
        className="card-kegiatan"
      >
        <Link to={`${id ? `${id}` : "#"}`}>{t("home.more")}</Link>
      </Card.Body>
    </Card>
  </>
)

export default withTrans(Index)
